import { useNotification } from '@/composable/useNotifications';
import TrailerType from '@/domain/TrailerType';
import TrailerTypeApiService from '@/services/api/TrailerTypeApiService';
import coreStore from '@/store/CoreStore';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

export default class TrailerTypeService {
    private notification = useNotification();

    private configStore = coreStore.getInstance().configStore;

    private trailerTypeApiService: TrailerTypeApiService;

    constructor() {
        this.trailerTypeApiService = new TrailerTypeApiService();
    }

    public async getAllTrailerTypes(): Promise<{ trailerTypes: Array<TrailerType>, success: boolean }> {
        const response = await this.trailerTypeApiService.getTrailerTypes();
        if (!response.success) {
            this.notification.showError(getTranslation('core.validation.errorGettingTrailerTypes'));
        }
        return {
            trailerTypes: response.data,
            success: response.success,
        };
    }

    public async getPreferredTrailerTypeForLocation(locationId: number) : Promise<TrailerType | undefined> {
        const { success, data } = await this.trailerTypeApiService.getPreferredTrailerTypeForLocation(locationId);
        return success ? data : undefined;
    }

    public async getPreferredTrailerTypeForRoute(fromLocationId: number, toLocationId: number) : Promise<TrailerType | undefined> {
        const { success, data } = await this.trailerTypeApiService.getPreferredTrailerTypeForRoute(fromLocationId, toLocationId);
        return success ? data : undefined;
    }

    public async addNewTrailerType(trailerType: TrailerType) : Promise<boolean> {
        const response = await this.trailerTypeApiService.addNewTrailerType(trailerType);
        if (!response.success) {
            this.notification.showError(response.message);
        } else {
            const newTrailerType = new TrailerType({
                ...trailerType,
                id: response.data,
            });
            this.configStore.addTrailerType(newTrailerType);
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.added')} ${trailerType.name}`);
        }
        return response.success;
    }

    public async updateTrailerType(trailerType: TrailerType) : Promise<boolean> {
        const response = await this.trailerTypeApiService.updateTrailerType(trailerType);
        if (!response.success) {
            this.notification.showError(response.message);
        } else {
            this.configStore.updateTrailerType(trailerType);
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.updated')} ${trailerType.name}`);
        }
        return response.success;
    }

    public async deleteTrailerType(trailerType: TrailerType) : Promise<boolean> {
        const response = await this.trailerTypeApiService.deleteTrailerType(trailerType);
        if (!response.success) {
            this.notification.showError(response.message);
        } else {
            this.configStore.deleteTrailerType(trailerType);
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.deleted')} ${trailerType.name}`);
        }
        return response.success;
    }
}
