import Direction from '@/domain/enums/Direction';
import { normalizeDate } from '@/functions/date';
import ReservationType from '@/domain/enums/ReservationType';
import ReservationTransaction from './ReservationTransaction';
import ReservationStatus from '@/domain/enums/ReservationStatus';

export default class Reservation {
    public id: number = 0;
    public shipmentId: number;
    public reservationDateTime: Date;
    public direction: Direction;
    public carrier: string;
    public dockName?: string = '';
    public reservationType: ReservationType;
    public status: ReservationStatus = ReservationStatus.OnTime;
    public lateReason?: string = '';
    public trailerNumber?: string = '';
    public transactions: Array<ReservationTransaction>;

    constructor(init: Reservation) {
        this.id = init.id;
        this.shipmentId = init.shipmentId;
        this.reservationDateTime = normalizeDate(init.reservationDateTime);
        this.direction = init.direction;
        this.carrier = init.carrier;
        this.dockName = init.dockName || '';
        this.reservationType = init.reservationType;
        this.status = init.status;
        this.lateReason = init.lateReason || '';
        this.trailerNumber = init.trailerNumber || '';
        this.transactions = init.transactions;
    }
}
