enum DockTypeEnum {
    ALL = 1,
    RECEIVING = 2,
    SHIPPING = 3,
}

export default class Dock {
    id: number = 0;

    typeId!: number;

    name: string = '';

    inService!: boolean;

    locationId!: number;

    constructor(init?: Partial<Dock>) {
        Object.assign(this, init);
    }

    get isNew(): boolean {
        return this.id === 0;
    }

    get isReceiving(): boolean {
        return this.typeId === DockTypeEnum.ALL || this.typeId === DockTypeEnum.RECEIVING;
    }

    get isShipping(): boolean {
        return this.typeId === DockTypeEnum.ALL || this.typeId === DockTypeEnum.SHIPPING;
    }
}
