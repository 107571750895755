import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import Item from '@/domain/Item';
import ItemSearchStringFilterDTO from '@/dtos/filters/ItemSearchStringFilterDTO';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class ItemApiService {
    public async getAllItems(): Promise<DataAccessResponse<Array<Item>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Item>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/item`,
        });

        return new DataListAccessResponseClass<Item>(response, Item).response;
    }

    public async getItemById(id: number): Promise<DataAccessResponse<Item>> {
        const response: AxiosResponse<DataAccessResponse<Item>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/item/${id}`,
        });

        return new DataAccessResponseClass<Item>(response, Item).response;
    }

    public async getItemsById(ids: Array<number>): Promise<DataAccessResponse<Array<Item>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Item>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/item`,
            params: { ids },
        });

        return new DataListAccessResponseClass<Item>(response, Item).response;
    }

    public async addNewItem(item: Item): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/item/add`,
            data: item,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateItem(item: Item): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/item/update`,
            data: item,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async deleteItem(id: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/item/${id}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async findItemsByName(filterDto: ItemSearchStringFilterDTO): Promise<DataAccessResponse<Array<Item>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Item>>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/item/search`,
            data: filterDto,
        });

        return new DataListAccessResponseClass<Item>(response, Item).response;
    }

    public async findItemByNameWithRouteLocations(filterDto: ItemSearchStringFilterDTO, fromLocationId: number, toLocationId: number): Promise<DataAccessResponse<Array<Item>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Item>>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/item/searchByNameInConfig/${fromLocationId}/${toLocationId}`,
            data: filterDto,
        });

        return new DataListAccessResponseClass<Item>(response, Item).response;
    }
}
