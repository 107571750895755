import ItemSearchStringFilter from '@/domain/filters/ItemSearchStringFilter';

export default class ItemSearchStringFilterDTO {
    public excludeItemIds?: number[];
    public ignoreCombinedContainers?: boolean;
    public includeOnlyCombinedContainers?: boolean;
    public includeOnlyRepairableItems?: boolean;
    public searchString?: string;

    constructor(init: ItemSearchStringFilter) {
        Object.assign(this, init);
    }
}
