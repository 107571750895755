/* eslint-disable import/prefer-default-export */
import { ItemVolumeKey, ItemWeightKey } from '@/composable/useMaxTrailerCompute';
import InventoryFlatDTO from '@/dtos/InventoryFlatDTO';
import InventoryGroupedDTO from '@/dtos/InventoryGroupedDTO';
import Item from '@/domain/Item';
import TrackedItem from '@/domain/TrackedItem';
import Transaction from '@/domain/Transaction';
import Shipment from '@/domain/Shipment';

export class ItemTableData {
    private readonly _item: Item = new Item();

    private _warningMsg: Array<string> = new Array<string>();

    private _transaction: Transaction = new Transaction();

    private _volumeKey: ItemVolumeKey = 'containerVolume';

    private _weightKey: ItemWeightKey = 'weight';

    private _inventory: Array<InventoryFlatDTO> = new Array<InventoryFlatDTO>();

    private _currentQty: number = 0;

    private _groupedInventory: InventoryGroupedDTO = new InventoryGroupedDTO();

    constructor(item: Item, transaction?: Transaction, volumeKey?: ItemVolumeKey) {
        this._item = item;
        if (transaction) {
            this._transaction = transaction;
        }
        if (volumeKey) {
            this._volumeKey = volumeKey;
        }
    }

    public static BuildAdminPlanningShipmentTableData(items: Array<Item>, transaction: Transaction): Array<ItemTableData> {
        return items.map((i) => {
            const key: ItemVolumeKey = i.collapsible ? 'collapsedVolume' : 'containerVolume';
            return new ItemTableData(i, transaction, key);
        });
    }

    public static BuildSupplierShipmentTableData(items: Array<Item>, transaction: Transaction): Array<ItemTableData> {
        return items.map((i) => {
            const key: ItemVolumeKey = i.collapsible ? 'collapsedVolume' : 'containerVolume';
            return new ItemTableData(i, transaction, key);
        });
    }

    // TODO: this is a very undesirable solution, the useMaxTrailerComposable shouldn't be coupled to ItemTableData
    // refactoring this so the composable only takes an item or transaction line list should be considered
    public static BuildShipmentTableData(shipment: Shipment): Array<ItemTableData> {
        const itemTableDataArray: Array<ItemTableData> = [];
        shipment.transactions.forEach((transaction) => {
            transaction.transactionLines.forEach((line) => {
                itemTableDataArray.push(new ItemTableData(line.item, transaction, line.item.collapsible ? 'collapsedVolume' : 'containerVolume'));
            });
        });
        return itemTableDataArray;
    }

    public static BuildOrderPackagingTableData(items: Array<Item>, transaction: Transaction, inventory: Array<InventoryFlatDTO>): Array<ItemTableData> {
        return items.map((i) => {
            const key: ItemVolumeKey = i.collapsible ? 'collapsedVolume' : 'containerVolume';
            const data = new ItemTableData(i, transaction, key);
            data._inventory = inventory;
            return data;
        });
    }

    set groupedInventory(groupedInv: InventoryGroupedDTO) {
        this._groupedInventory = groupedInv;
    }

    get groupedInventory(): InventoryGroupedDTO {
        return this._groupedInventory;
    }

    set currentQty(qty: number) {
        this._currentQty = qty;
    }

    get currentQty() {
        return this._currentQty;
    }

    get name(): string {
        return this._item.name;
    }

    get shortName(): string {
        return this._item.shortName;
    }

    get tags(): Array<TrackedItem> {
        return this._transaction.getTrackedItemsByItem(this._item);
    }

    get actualQuantity(): number | null {
        const actualQty = this._transaction.transactionLines.find((line) => line.item.id === this._item.id)?.actualQuantity || null;
        return actualQty;
    }

    get plannedQuantity(): number | null {
        return this._transaction.transactionLines.find((line) => line.item.id === this._item.id)?.plannedQuantity || null;
    }

    get estimatedQuantity(): number | null {
        return this._transaction.transactionLines.find((line) => line.item.id === this._item.id)?.estimatedQuantity || null;
    }

    get receivedQuantity(): number | null {
        return this._transaction.transactionLines.find((line) => line.item.id === this._item.id)?.receivedQuantity || null;
    }

    get requestedQuantity(): number | null {
        return this._transaction.transactionLines.find((line) => line.item.id === this._item.id)?.requestedQuantity || null;
    }

    get item(): Item {
        return this._item;
    }

    get quantityOnHand(): number {
        return this._inventory.find((x) => x.itemId === this._item.id)?.quantity || 0;
    }

    set warningMessage(messages: Array<string>) {
        this._warningMsg = messages;
    }

    get warningMessage(): Array<string> {
        return this._warningMsg;
    }

    set volumeKey(key: ItemVolumeKey) {
        this._volumeKey = key;
    }

    set weightKey(key: ItemWeightKey) {
        this._weightKey = key;
    }

    get containerVolume(): number | null {
        return this._item[this._volumeKey];
    }

    get containerWeight(): number | null {
        return this._item[this._weightKey];
    }

    get palletQty(): number {
        return this._item.palletQty;
    }

    get customerItemNumber(): string {
        return this._item.customerItemNumber || '';
    }

    get imageUrlThumb(): string | undefined {
        return this._item.imageUrlThumb;
    }

    get imageUrlFull(): string | undefined {
        return this._item.imageUrlFull;
    }
}
