import RiskFlag from '@/domain/enums/RiskFlag';
import InventoryCategory from '@/domain/InventoryCategory';
import { API_SERVICE_URL } from '@/config/env';
import { AuthStore } from '@/store/AuthStore';

type CategoryQuantity = {
    categoryId: number;
    quantity: number;
    quantityWithSortedQty: number;
};

export default class InventoryGroupedDTO {
    constructor(init?: Partial<InventoryGroupedDTO>) {
        Object.assign(this, init);
        this.imageUrlFull = init?.imageFileName ? `${API_SERVICE_URL}/storage/images/${AuthStore.getInstance().clientId}/${init?.imageFileName}` : undefined;
        this.imageUrlThumb = init?.imageFileName ? `${API_SERVICE_URL}/storage/thumbnails/${AuthStore.getInstance().clientId}/${init?.imageFileName}` : undefined;
    }

    public itemId!: number;

    public itemName!: string;

    public locationId!: number;

    public locationName!: string;

    public quantityInbound!: number;

    public quantityOutbound!: number;

    public imageFileName?: string;

    public imageUrlFull? = 'https://www.caseman.com/wp-content/uploads/2018/02/SC1818-18-1-600x525.jpg';

    public imageUrlThumb? = 'https://cdn.vox-cdn.com/thumbor/TmgXcq6_4URVd0YN0SotUf5WYeA=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/9556001/chicks.0.0.0.jpg';

    public categoryQuantities: CategoryQuantity[] = [];

    public riskFlag: RiskFlag = RiskFlag.NoFlag;

    public riskScore?: number;

    public customerItemNumber!: string;

    public getInventoryForCategory(category: InventoryCategory, adjustForSorting: boolean = false): number {
        return this.getInventoryForCategoryId(category.id, adjustForSorting);
    }

    public getInventoryForCategoryId(categoryId: number, adjustForSorting: boolean = false): number {
        const categoryQuantity = this.categoryQuantities?.find((x) => x.categoryId === categoryId);

        if (categoryQuantity) {
            return adjustForSorting ? categoryQuantity.quantityWithSortedQty : categoryQuantity.quantity;
        }

        return 0;
    }
}
