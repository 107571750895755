import { useNotification } from '@/composable/useNotifications';
import SupplierShipmentAPIService from '@/services/api/SupplierShipmentAPIService';
import Shipment from '@/domain/Shipment';
import ShipmentDTO from '@/modules/floortrak/domain/jigsaw/dto/ShipmentDTO';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { formatDateTimeUI } from '@/functions/date';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

export default class SupplierShipmentService {
    private apiService: SupplierShipmentAPIService;

    private notification = useNotification();

    constructor() {
        this.apiService = new SupplierShipmentAPIService();
    }

    public async searchAndActivateTransaction(transactionId: number, locationId: number): Promise<DataAccessResponse<Shipment>> {
        const resp = await this.apiService.searchAndActivateTransaction(transactionId, locationId);

        return resp;
    }

    private async saveNewShipment(shipment: Shipment, apiServiceCallback: (dto: ShipmentDTO) => Promise<DataAccessResponse<number>>, suppressNotification: boolean = false): Promise<number> {
        const resp = await apiServiceCallback(shipment.toDTO());
        if (resp.success && !suppressNotification) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.shipmentSaved'));
        }
        return resp.success ? resp.data : 0;
    }

    public async saveNewPickedShipment(shipment: Shipment, suppressNotification: boolean = false): Promise<number> {
        return this.saveNewShipment(shipment, this.apiService.saveNewPickedShipment, suppressNotification);
    }

    public async saveNewScheduledShipment(shipment: Shipment): Promise<number> {
        return this.saveNewShipment(shipment, this.apiService.saveNewScheduledShipment);
    }

    public async createAllocatedShipmentFromPlannedTransaction(transactionId: number): Promise<{ shipmentDetails: Shipment; success: boolean }> {
        const response = await this.apiService.createAllocatedShipmentFromPlannedTransaction(transactionId);
        if (response.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.transactionAllocated'));
        }

        return { shipmentDetails: response.data, success: response.success };
    }

    public async createAllocatedShipmentFromPlannedTransactionForLocation(transactionId: number, fromLocationId: number): Promise<{ shipmentDetails: Shipment; success: boolean }> {
        const response = await this.apiService.createAllocatedShipmentFromPlannedTransactionForLocation(transactionId, fromLocationId);
        if (response.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.transactionAllocated'));
        }

        return { shipmentDetails: response.data, success: response.success };
    }

    public async createAllocatedShipmentsFromPlannedTransactions(transactionIds: number[]): Promise<{ createdCount: number; success: boolean }> {
        const response = await this.apiService.createAllocatedShipmentsFromPlannedTransactions(transactionIds);
        if (response.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.transactionsAllocated'));
        }

        return { createdCount: response.data, success: response.success };
    }

    public async createAllocatedShipmentsFromPlannedTransactionsForLocation(transactionIds: number[], fromLocationId: number): Promise<{ createdCount: number; success: boolean }> {
        const response = await this.apiService.createAllocatedShipmentsFromPlannedTransactionsForLocation(transactionIds, fromLocationId);
        if (response.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.transactionsAllocated'));
        }

        return { createdCount: response.data, success: response.success };
    }

    public async getShipmentDetailsForTransaction(transactionId: number): Promise<{ shipmentDetails: Shipment; success: boolean }> {
        const resp = await this.apiService.getShipmentDetails(transactionId);
        if (!resp.success) {
            this.notification.showError(resp.message);
        }
        return { shipmentDetails: resp.data, success: resp.success };
    }

    private async updateShipment(shipment: Shipment, apiServiceCallback: (dto: ShipmentDTO) => Promise<DataAccessResponse<Shipment>>): Promise<Shipment | undefined> {
        const resp = await apiServiceCallback(shipment.toDTO());
        if (resp.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.updatedShipment'));
            return resp.data;
        }
        return undefined;
    }

    public async updateExistingScheduledShipment(shipment: Shipment): Promise<Shipment | undefined> {
        const apiCallback = shipment.transactions[0].canAdminAdjust()
            ? this.apiService.adjustConfirmedShipmentData : this.apiService.updateExistingScheduledShipment;

        return this.updateShipment(shipment, apiCallback);
    }

    public async updateExistingShipment(shipment: Shipment): Promise<Shipment | undefined> {
        const apiCallback = shipment.transactions[0].canAdminAdjust()
            ? this.apiService.adjustConfirmedShipmentData : this.apiService.updateExistingShipment;

        return this.updateShipment(shipment, apiCallback);
    }

    public async shipNow(shipmentId: number): Promise<boolean> {
        const resp = await this.apiService.shipNow(shipmentId);
        if (resp.success) {
            this.notification.showSuccess(getTranslation('core.common.transactionMovedToInTransit'));
        }
        return resp.success;
    }

    public async getShipmentReceipt(shipmentId: number): Promise<DataAccessResponse<Blob>> {
        return this.apiService.getShipmentReceipt(shipmentId, formatDateTimeUI(new Date()));
    }

    public async moveTransactionsToHeld(shipmentId: number): Promise<number> {
        const { success, data } = await this.apiService.moveTransactionsToHeld(shipmentId);

        if (success) {
            this.notification.showSuccess(getTranslation('core.common.transactionsMovedToHeld'));
        }

        return success ? data : 0;
    }

    public async updateShipmentNotes(shipmentId: number, notes: string): Promise<boolean> {
        const response = await this.apiService.updateShipmentNotes(shipmentId, notes);
        return response.success;
    }
}
