
import { defineComponent, PropType, computed } from 'vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import { TransactionStatus } from '@/domain/TransactionStatus';
import Transaction from '@/domain/Transaction';
import BProgressBar from '@/components/bootstrap-library/BProgressBar.vue';
import { UseMaxTrailerCompute } from '@/composable/useMaxTrailerCompute';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'transaction-footer',
    components: {
        BProgressBar,
        SmartTrakFooter,
    },
    props: {
        transaction: {
            type: Object as PropType<Transaction>,
            required: true,
        },
        maxTrailerComposable: {
            type: Object as PropType<UseMaxTrailerCompute>,
        },
    },
    emits: ['submit', 'shipNow'],
    setup(props) {
        const containerCountMessage = computed((): string => (props.transaction.status < TransactionStatus.PICKED
            ? `${getTitleCaseTranslation('core.domain.totalPlannedContainers')}: ${props.transaction.totalPlannedItems}`
            : `${getTitleCaseTranslation('core.domain.totalItems')}: ${props.transaction.totalItems}`));

        return {
            containerCountMessage,
        };
    },
});
