import axios, { AxiosResponse } from 'axios';
import Dock from '@/domain/Dock';
import DockType from '@/domain/DockType';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';

export default class DockApiService {
    public async addDock(dock: Dock): Promise<DataAccessResponse<Dock>> {
        const response: AxiosResponse<DataAccessResponse<Dock>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/dock`,
            data: dock,
        });

        return new DataAccessResponseClass<Dock>(response).response;
    }

    public async updateDock(dock: Dock): Promise<DataAccessResponse<Dock>> {
        const response: AxiosResponse<DataAccessResponse<Dock>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/dock`,
            data: dock,
        });

        return new DataAccessResponseClass<Dock>(response).response;
    }

    public async deleteDock(id: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/dock/${id}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async getDocksByLocationId(locationId: number): Promise<DataAccessResponse<Array<Dock>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Dock>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/dock/docks-by-locationId/${locationId}`,
        });
        return new DataListAccessResponseClass<Dock>(response, Dock).response;
    }

    public async getDockTypes(): Promise<DataAccessResponse<Array<DockType>>> {
        const response: AxiosResponse<DataAccessResponse<Array<DockType>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/dock/dockTypes`,
        });
        return new DataListAccessResponseClass<DockType>(response, DockType).response;
    }
}
