import {
    computed, ComputedRef, Ref, unref,
} from 'vue';
import TrailerType from '@/domain/TrailerType';
import { ItemTableData } from '@/domain/ItemTableData';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

export type ItemQtyKey = 'requestedQuantity' | 'actualQuantity' | 'plannedQuantity';
export type ItemVolumeKey = 'containerVolume' | 'collapsedVolume';
export type ItemWeightKey = 'weight';

export type MaxTrailerProps = {
    itemList: Ref<Array<ItemTableData>> | ComputedRef<Array<ItemTableData>>,
    trailerType: Ref<TrailerType> | ComputedRef<TrailerType>,
    itemQtyKey: ItemQtyKey | Ref<ItemQtyKey>,
};

export type UseMaxTrailerCompute = {
    confirmOverCapacity: () => Promise<boolean>;
    utilizedVolume: ComputedRef<number>;
    volumePercent: ComputedRef<number>;
    percentVolumeUsed: ComputedRef<string>;
    overflowVolumeMessage: ComputedRef<string>;
    utilizedWeight: ComputedRef<number>;
    weightPercent: ComputedRef<number>;
    percentWeightUsedMessage: ComputedRef<string>;
    overflowWeightMessage: ComputedRef<string>;
    isOverCapacity: ComputedRef<boolean>;
};

export function useMaxTrailerCompute(props: MaxTrailerProps): UseMaxTrailerCompute {
    const { confirm } = useDialogBox();

    const utilizedVolume = computed((): number => {
        const qtyKey = unref(props.itemQtyKey);
        let val = 0;

        props.itemList.value.forEach((item) => {
            val += (item[qtyKey] || 0) * (item.containerVolume || 0);
        });
        return val;
    });

    const volumePercent = computed((): number => {
        if (props.trailerType.value.cubicVolume) {
            return (utilizedVolume.value / props.trailerType.value.cubicVolume) * 100;
        }

        return 0;
    });

    const percentVolumeUsed = computed((): string => {
        const displayVal = Number.isNaN(volumePercent.value) ? 0 : volumePercent.value.toFixed(0);
        return `${getTitleCaseTranslation('core.common.trailerSpaceUtilized')}: ${displayVal}%`;
    });

    const overflowVolumeMessage = computed((): string => getTranslation('core.validation.exceededTrailerCapacityAdjustQuantity'));

    const utilizedWeight = computed((): number => {
        const qtyKey = unref(props.itemQtyKey);
        let val = 0;

        props.itemList.value.forEach((item) => {
            val += (item[qtyKey] || 0) * (item.containerWeight || 0);
        });
        return val;
    });

    const weightPercent = computed((): number => {
        if (props.trailerType.value.maxLoadCapacity) {
            return (utilizedWeight.value / props.trailerType.value.maxLoadCapacity) * 100;
        }

        return 0;
    });

    const percentWeightUsedMessage = computed((): string => {
        const displayVal = Number.isNaN(weightPercent.value) ? 0 : weightPercent.value.toFixed(0);
        return `${getTitleCaseTranslation('core.common.trailerWeightUtilized')}: ${displayVal}%`;
    });

    const overflowWeightMessage = computed((): string => getTranslation('core.validation.exceededTrailerWeightAdjustQuantity'));

    const isOverCapacity = computed((): boolean => volumePercent.value > 100 || weightPercent.value > 100);

    function dialogDisplayMessages(): string | null {
        const messages: Array<string> = [];

        if (props.trailerType.value.cubicVolume && utilizedVolume.value >= props.trailerType.value.cubicVolume) {
            const overage = (utilizedVolume.value - props.trailerType.value.cubicVolume).toFixed(2);
            // eslint-disable-next-line max-len
            messages.push(`${getTranslation('core.validation.youHaveExceededThe')} <span style="font-weight: bold">${getTranslation('core.validation.volumeCapacity')}</span> ${getTranslation('core.common.ofTheTrailerBy')} <span style="font-weight: bold">${overage} ${getTranslation('core.common.cubicInches')}</span>`);
        }

        if (props.trailerType.value.maxLoadCapacity && utilizedWeight.value >= props.trailerType.value.maxLoadCapacity) {
            const overage = (utilizedWeight.value - props.trailerType.value.maxLoadCapacity).toFixed(2);
            // eslint-disable-next-line max-len
            messages.push(`${getTranslation('core.validation.youHaveExceededThe')} <span style="font-weight: bold">${getTranslation('core.validation.weightCapacity')}</span> ${getTranslation('core.common.ofTheTrailerBy')} <span style="font-weight: bold">${overage} ${getTranslation('core.common.pounds')}</span>`);
        }

        return messages.length > 0 ? messages.join(' <br><br> ') : null;
    }

    async function confirmOverCapacity(): Promise<boolean> {
        let confirmed = true;

        if (dialogDisplayMessages()) {
            confirmed = await confirm({
                title: getTitleCaseTranslation('core.validation.trailerLoadExceeded'),
                vHtml: true,
                message: dialogDisplayMessages() as string,
            });
        }

        return confirmed;
    }

    return {
        confirmOverCapacity,
        utilizedVolume,
        volumePercent,
        percentVolumeUsed,
        overflowVolumeMessage,
        utilizedWeight,
        weightPercent,
        percentWeightUsedMessage,
        overflowWeightMessage,
        isOverCapacity,
    };
}
