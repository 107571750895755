
import { defineComponent, ref, PropType } from 'vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import { ItemTableData } from '@/domain/ItemTableData';
import { TransactionStatus } from '@/domain/TransactionStatus';

export default defineComponent({
    name: 'transaction-quantity-number-input',
    components: {
        BFormInput,
    },
    props: {
        disabled: Boolean,
        hideStepper: Boolean,
        item: {
            required: true,
            type: Object as PropType<ItemTableData>,
        },
        min: Number,
        transactionStatus: {
            required: true,
            type: Number as PropType<TransactionStatus>,
        },
    },
    emits: ['change'],
    setup(props, context) {
        const numberInputClass = 'numberInput';
        const inputVal = ref(props.transactionStatus < TransactionStatus.PICKED ? props.item.plannedQuantity : props.item.actualQuantity);

        function emitChangeEvent(qty: string | number, attemptedValue: string | number) {
            context.emit('change', qty, attemptedValue);
        }

        return {
            emitChangeEvent,
            inputVal,
            numberInputClass,
        };
    },
});
