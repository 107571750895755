import Transaction from '@/domain/Transaction';
import ShipmentDTO from '@/modules/floortrak/domain/jigsaw/dto/ShipmentDTO';
import ShipmentDetails from '@/domain/ShipmentDetails';

export default class Shipment extends ShipmentDetails {
    private _transactions: Array<Transaction> = [];

    constructor(init?: Partial<Shipment>) {
        super(init);

        if (init?.transactions) {
            init.transactions.forEach((tr) => {
                this.addTransaction(tr);
            });
        }
    }

    public addTransaction(transaction?: Transaction) {
        if (transaction) this._transactions.unshift(new Transaction(transaction));
        else this._transactions.unshift(new Transaction());
    }

    public clearTransactions() {
        this._transactions = [];
    }

    get carrierName(): string | undefined {
        return this.carrier?.name;
    }

    get dockName(): string | undefined {
        return this.dock?.name;
    }

    get trailerTypeName(): string | undefined {
        return this.trailerType?.name;
    }

    get localArrivalTime(): string | undefined {
        return this.arrivalDate?.toLocaleString();
    }

    get transactions(): Array<Transaction> {
        return this._transactions;
    }

    toDTO(): ShipmentDTO {
        return new ShipmentDTO(this);
    }

    isEstimateFinalized(): boolean {
        return this._transactions[0]?.isEstimateFinalized;
    }
}
