import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_button, {
      disabled: _ctx.disabled,
      variant: _ctx.buttonVariant,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$refs.file.click()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_faicon, {
          icon: "camera",
          style: {"margin-right":"3px"}
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.buttonText), 1)
      ]),
      _: 1
    }, 8, ["disabled", "variant"]),
    _createElementVNode("input", {
      ref: "file",
      class: "form-control",
      type: "file",
      accept: "image/*",
      capture: "environment",
      style: {"display":"none"},
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeImage && _ctx.changeImage(...args)))
    }, null, 544)
  ]))
}