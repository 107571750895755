
import { defineComponent, onMounted, PropType, reactive } from 'vue';
import { ItemType } from '@/domain/enums/ItemType';
import ItemService from '@/services/ItemService';
import QuantityPicker from '@/components/QuantityPicker.vue';
import Item from '@/domain/Item';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import ItemSearchStringFilter from '@/domain/filters/ItemSearchStringFilter';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import NumberInput from '@/components/inputs/NumberInput.vue';

type State = {
    itemsOnRoute: Item[];
    item: Item | null;
    quantity: number;
};

export default defineComponent({
    name: 'item-picker',
    components: {
        NumberInput,
        Thumbnail,
        DropdownAutocompleteSingleSelect,
        BSpinner,
        QuantityPicker,
    },
    props: {
        itemType: {
            type: String as PropType<typeof ItemType[keyof typeof ItemType]>,
            default: undefined,
        }, // converts ItemType to string literal
        shipping: {
            type: Object as PropType<{ toLocationId: number; fromLocationId: number }>,
            default: () => ({
                toLocationId: 0,
                fromLocationId: 0,
            }),
        },
        buttonTitle: { type: String, default: getTitleCaseTranslation('core.button.add') },
        allowQuantityChange: {
            type: Boolean,
            default: () => false,
        },
        searchOnly: {
            type: Boolean,
            default: () => false,
        },
        quantity: { type: Number, default: 0 },
        fontSize: { type: Number, default: 0 },
        errorMsg: { type: String, default: '' },
        disabledQtyPicker: Boolean,
        busy: Boolean,
        excludeItemIds: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        ignoreCombinedContainers: {
            type: Boolean,
            default: () => false,
        },
        includeOnlyCombinedContainers: {
            type: Boolean,
            default: () => false,
        },
        includeOnlyRepairableItems: Boolean,
        placeholder: { type: String, default: '' },
        isMobile: {
            type: Boolean,
            default: false,
        },
        focusOnLoad: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['onSelect', 'onClear'],

    setup(props, context) {
        const itemService = new ItemService();
        const itemTypesThatOnlyNeedItemName: (typeof ItemType[keyof typeof ItemType] | undefined)[] = [
            ItemType.COMBINED,
            ItemType.PUT_AWAY,
            ItemType.RECEIVING,
            ItemType.WAREHOUSE_MOVEMENT,
            ItemType.IN_HOUSE_REPAIRS,
            ItemType.ALL,
        ];
        const state = reactive<State>({
            itemsOnRoute: [],
            item: null,
            quantity: props.quantity ? props.quantity : 0,
        });

        function getItemSearchStringFilter(searchStr: string) {
            return new ItemSearchStringFilter({
                excludeItemIds: props.excludeItemIds,
                ignoreCombinedContainers: props.ignoreCombinedContainers,
                includeOnlyCombinedContainers: props.includeOnlyCombinedContainers,
                includeOnlyRepairableItems: props.includeOnlyRepairableItems,
                searchString: searchStr,
            });
        }
        async function getItemsOnRoute() {
            state.itemsOnRoute = await itemService.findItemByConfig(getItemSearchStringFilter(''), props.shipping.fromLocationId, props.shipping.toLocationId);
        }

        onMounted(async () => {
            if (props.itemType === ItemType.SHIPPING) {
                await getItemsOnRoute();
            }
        });

        async function search(searchStr: string) {
            if (itemTypesThatOnlyNeedItemName.includes(props.itemType)) return itemService.findItemsByName(getItemSearchStringFilter(searchStr));
            // return search by name without config
            return state.itemsOnRoute.filter((item) => item.displayName.toLowerCase().includes(searchStr.toLowerCase()));
        }

        async function onAutocompleteSelect(value: Item) {
            if (value) {
                state.item = new Item(value);
                if (!props.allowQuantityChange) {
                    context.emit('onSelect', { item: state.item });
                }
            } else {
                state.item = null;
                context.emit('onSelect', { item: null });
            }
            state.quantity = 0;
        }

        function onSelect() {
            context.emit('onSelect', { item: state.item, quantity: state.quantity });
        }

        function onClear() {
            context.emit('onClear');
        }

        return {
            search,
            state,
            onAutocompleteSelect,
            onSelect,
            onClear,
        };
    },
});
