import ReservationApiService from './api/ReservationApiService';
import ShipmentReservationDTO from '@/dtos/ReservationlessShipmentDTO';
import PlannedReservation from '@/domain/PlannedReservation';
import Reservation from '@/domain/Reservation';

export default class ReservationService {
    private reservationApiService: ReservationApiService;

    constructor() {
        this.reservationApiService = new ReservationApiService();
    }

    public async getReservationsByServiceCenter(serviceCenterId: number, startDate: Date, endDate: Date): Promise<Array<Reservation>> {
        const response = await this.reservationApiService.getReservationsByServiceCenter(serviceCenterId, startDate, endDate);
        return response.data;
    }

    public async getReservationsInTimeslotByServiceCenter(serviceCenterId: number, timeSlot: Date): Promise<Array<Reservation>> {
        const response = await this.reservationApiService.getReservationsInTimeslotByServiceCenter(serviceCenterId, timeSlot);
        return response.data;
    }

    public async getReservationlessShipmentsByServiceCenter(serviceCenterId: number, startDate: Date, endDate: Date): Promise<Array<ShipmentReservationDTO>> {
        const response = await this.reservationApiService.getReservationlessShipmentsByServiceCenter(serviceCenterId, startDate, endDate);
        return response.data;
    }

    public async getPlannedReservationsByServiceCenterForTimeRange(serviceCenterId: number, date: Date, hourRange: number): Promise<Array<PlannedReservation>> {
        const response = await this.reservationApiService.getPlannedReservationsByServiceCenterForTimeRange(serviceCenterId, date, hourRange);
        return response.data;
    }

    public async addShipmentReservation(shipmentId: number, reservationDateTime: Date): Promise<number> {
        const response = await this.reservationApiService.addShipmentReservation(shipmentId, reservationDateTime);
        return response.data;
    }

    public async addPlannedReservation(plannedReservation: PlannedReservation): Promise<number> {
        const response = await this.reservationApiService.addPlannedReservation(plannedReservation.toDTO());
        return response.data;
    }

    public async associatePlannedReservationWithShipment(plannedReservationId: number, shipmentId: number): Promise<number> {
        const response = await this.reservationApiService.associatePlannedReservationWithShipment(plannedReservationId, shipmentId);
        return response.data;
    }

    public async flagShipmentReservationAsLate(shipmentId: number, lateReason: string): Promise<{ success: boolean, shipmentId: number }> {
        const response = await this.reservationApiService.flagShipmentReservationAsLate(shipmentId, lateReason);
        return { success: response.success, shipmentId: response.data };
    }

    public async markShipmentReservationOnTime(shipmentId: number): Promise<{ success: boolean, shipmentId: number }> {
        const response = await this.reservationApiService.markShipmentReservationOnTime(shipmentId);
        return { success: response.success, shipmentId: response.data };
    }

    public async markShipmentReservationTrailerArrived(shipmentId: number, trailerNumber: string): Promise<{ success: boolean, shipmentId: number }> {
        const response = await this.reservationApiService.markShipmentReservationTrailerArrived(shipmentId, trailerNumber);
        return { success: response.success, shipmentId: response.data };
    }

    public async getPlannedReservationById(id: number): Promise<PlannedReservation> {
        const response = await this.reservationApiService.getPlannedReservationById(id);
        return response.data;
    }

    public async updatePlannedReservations(reservationsToUpdate: Array<PlannedReservation>): Promise<boolean> {
        const response = await this.reservationApiService.updatePlannedReservations(reservationsToUpdate);
        return response.success;
    }
}
