enum EntityType {
    TRANSACTION = 1,
    TRANSACTION_LINES = 2,
    SHIPMENTS = 3,
    USER = 4,
    ROLE = 5,
    LOCATION = 6,
    ITEM = 7,
    CARRIER = 8,
    TRAILER_TYPE = 9,
    INVENTORY_ADJUSTMENTS = 10,
    LOCATION_ADDRESS = 11,
    LOCATION_DOCK = 12,
    SHIPPING_NOTICES = 13,
    FREIGHT_CODE = 14,
    LOCATION_TYPE = 15,
    ITEM_TYPE = 16,
    CYCLE_COUNT_PREFERENCE = 17,
    SETTING = 22,
    EQUIPMENT_TYPE = 23,
    EQUIPMENT = 24,
    REPAIR_PART = 25,
    REPAIR_PART_INVENTORY = 26,
}
// have to use a separate export statement since linter doesn't like `export default enum`
export default EntityType;
