import PlannedReservation from '@/domain/PlannedReservation';

export default class PlannedReservationDTO {
    public fromLocationId: number;
    public toLocationId: number;
    public carrierId: number;
    public reservationDateTime: Date;
    public carrierReferenceNumber: string;
    public partnerReferenceNumber: string;
    public trailerNumber: string;

    constructor(plannedReservation: PlannedReservation) {
        this.fromLocationId = plannedReservation.fromLocation.id;
        this.toLocationId = plannedReservation.toLocation.id;
        this.carrierId = plannedReservation.carrier.id;
        this.reservationDateTime = plannedReservation.reservationDateTime;
        this.carrierReferenceNumber = plannedReservation.carrierReferenceNumber;
        this.partnerReferenceNumber = plannedReservation.partnerReferenceNumber;
        this.trailerNumber = plannedReservation.trailerNumber;
    }
}
