import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!

  return (_openBlock(), _createBlock(_component_b_form_input, {
    type: "number",
    "model-value": _ctx.inputVal,
    class: _normalizeClass(_ctx.numberInputClass),
    disabled: _ctx.disabled,
    "hide-stepper": _ctx.hideStepper,
    min: _ctx.min,
    onChange: _cache[0] || (_cache[0] = 
            (qty, attemptedValue) =>
                _ctx.emitChangeEvent(qty, attemptedValue)
        )
  }, null, 8, ["model-value", "class", "disabled", "hide-stepper", "min"]))
}