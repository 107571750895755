import ReservationTransaction from '@/domain/ReservationTransaction';
import Direction from '@/domain/enums/Direction';

export default class ReservationlessShipmentDTO {
    public shipmentId: number;
    public carrier: string;
    public direction: Direction;
    public transactions: Array<ReservationTransaction>;

    constructor(init: ReservationlessShipmentDTO) {
        this.shipmentId = init.shipmentId;
        this.carrier = init.carrier;
        this.direction = init.direction;
        this.transactions = init.transactions;
    }
}
