export default class RouteConfigDetailsDTO {
    constructor(init: RouteConfigDetailsDTO) {
        Object.assign(this, init);
    }

    public routeId!: number;

    public preferredTrailerTypeId!: number;

    public transitTime!: number;

    public shipFromInventoryCategoryIds!:Array<number>;

    public shippingLoadTypeId?: number | null;

    public defaultCarrierId?: number | null;

    public minTrailerPercent?: number | null;

    public maxLTLDayRange?: number | null;
}
