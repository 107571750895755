import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15f72415"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_progress_bar = _resolveComponent("b-progress-bar")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!

  return (_openBlock(), _createBlock(_component_smart_trak_footer, {
    "content-direction": "space-between",
    class: "transaction-footer"
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.containerCountMessage), 1),
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.transaction.hasShipped)
          ? (_openBlock(), _createBlock(_component_b_progress_bar, {
              key: 0,
              percent: _ctx.maxTrailerComposable?.volumePercent,
              message: _ctx.maxTrailerComposable?.percentVolumeUsed,
              "overflow-message": _ctx.maxTrailerComposable?.overflowVolumeMessage
            }, null, 8, ["percent", "message", "overflow-message"]))
          : _createCommentVNode("", true),
        (!_ctx.transaction.hasShipped)
          ? (_openBlock(), _createBlock(_component_b_progress_bar, {
              key: 1,
              percent: _ctx.maxTrailerComposable?.weightPercent,
              message: _ctx.maxTrailerComposable?.percentWeightUsedMessage,
              "overflow-message": _ctx.maxTrailerComposable?.overflowWeightMessage
            }, null, 8, ["percent", "message", "overflow-message"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
      ])
    ]),
    _: 3
  }))
}