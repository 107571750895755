import Location from '@/domain/Location';
import Item from '@/domain/Item';
import InventoryCategory from './InventoryCategory';
import InventoryCategoryShippingRouteConfiguration from './InventoryCategoryShippingRouteConfiguration';

export default class LocationRouteConfig {
    id = 0;

    toLocation!: Location;

    fromLocation!: Location;

    preferredTrailerTypeId!: number;

    itemCount = 0;

    items: Array<Item> = [];

    shipFromInventoryCategories: Array<InventoryCategory> = [];

    inventoryCategoryShippingRouteConfigurations: Array<InventoryCategoryShippingRouteConfiguration> = [];

    transitTime = 0;
    shippingLoadTypeId?: number;
    defaultCarrierId?: number;
    minTrailerPercent?: number;
    maxLTLDayRange?: number;
    isLTL: boolean = false;

    get fromLocationId(): number {
        return this.fromLocation.id;
    }

    get toLocationId(): number {
        return this.toLocation.id;
    }

    constructor(init?: Partial<LocationRouteConfig>) {
        Object.assign(this, init);
        this.fromLocation = new Location(init?.fromLocation);
        this.toLocation = new Location(init?.toLocation);
        this.items = init?.items?.map((i) => new Item(i)) || [];
        this.shipFromInventoryCategories = init?.inventoryCategoryShippingRouteConfigurations?.map((i) => new InventoryCategory(i.inventoryCategory)) || [];
    }
}
