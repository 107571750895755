import { computed, ComputedRef } from 'vue';
import { RouteRecordName } from 'vue-router';
import Shipment from '@/domain/Shipment';
import Transaction from '@/domain/Transaction';
import { TransactionStatus } from '@/domain/TransactionStatus';
import TransactionState from '@/interfaces/TransactionState';
import router from '@/router';
import Permissions from '@/services/permissions/Permissions';
import SupplierShipmentService from '@/services/SupplierShipmentService';
import TransactionService from '@/services/TransactionService';
import useValidator from '@/validation/useValidator';
import { FormValidationKey, ValidationResult } from '@/validation/types';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

export interface SupplierShippingProps {
    transactionListRouteName: RouteRecordName;
    state: TransactionState;
    formValidationKeyForTransactions?: FormValidationKey;
    formValidationKeyForShipments?: FormValidationKey;
}

export interface UseSupplierShipping {
    closeoutDisputed: () => Promise<void>;
    goToTransactionList: () => void;
    isDirty: ComputedRef<boolean>;
    isReceivedTransactionLineFieldsDisabled: ComputedRef<boolean>;
    moveTransactionOutOfFloor: (moveTransactionAction: (shipmentId: number) => Promise<any>) => Promise<void>;
    shipNow: () => Promise<void>;
    transactionLineErrors: ComputedRef<string>;
    transactionLineFields: ComputedRef<string[]>;
    updateExistingShipment: () => Promise<boolean>;
    validateTransactionForm?: (form: Transaction) => void;
    validationTransactionResult?: ValidationResult<Transaction>;
    validateShipmentForm?: (form: Shipment) => void;
    validationShipmentResult?: ValidationResult<Shipment>;
}

export default function useSupplierShipping({
    transactionListRouteName, state, formValidationKeyForShipments, formValidationKeyForTransactions,
}: SupplierShippingProps): UseSupplierShipping {
    const shipmentService = new SupplierShipmentService();
    const transactionService = new TransactionService();
    const { confirm } = useDialogBox();

    let validateShipmentForm: ((form: Shipment) => void) | undefined;
    let validateTransactionForm: ((form: Transaction) => void) | undefined;
    let validationShipmentResult: ValidationResult<Shipment> | undefined;
    let validationTransactionResult: ValidationResult<Transaction> | undefined;

    if (formValidationKeyForShipments) {
        const { validateForm, validationResult } = useValidator<Shipment>(formValidationKeyForShipments);

        validateShipmentForm = validateForm;
        validationShipmentResult = validationResult;
    }

    if (formValidationKeyForTransactions) {
        const { validateForm, validationResult } = useValidator<Transaction>(formValidationKeyForTransactions);

        validateTransactionForm = validateForm;
        validationTransactionResult = validationResult;
    }

    const { validateForm: validateCloseoutDisputedTransactionForm, validationResult: validationCloseoutDisputedTransactionResult } = useValidator<Transaction>('transaction-closeout-dispute');

    // eslint-disable-next-line max-len
    const updateExistingShipmentBasedOnValidationType = async (): Promise<Shipment | undefined> => (formValidationKeyForTransactions === 'transaction-outbound-scheduled' || formValidationKeyForTransactions === 'transaction-outbound-shipping'
        ? shipmentService.updateExistingScheduledShipment(state.shipment as Shipment)
        : shipmentService.updateExistingShipment(state.shipment as Shipment));

    async function updateExistingShipment(): Promise<boolean> {
        let success = false;

        if (validateShipmentForm) {
            validateShipmentForm(state.shipment as Shipment);
        }

        if (validateTransactionForm) {
            validateTransactionForm(state.transaction as Transaction);
        }

        if (validationShipmentResult?.isValid || validationTransactionResult?.isValid) {
            state.saving = true;
            const resp = await updateExistingShipmentBasedOnValidationType();
            if (resp) {
                state.shipment.id = resp.id;
                state.shipment.timestamp = resp.timestamp;
                state.shipment.transactions[0].status = resp.transactions[0].status;
                state.shipment.transactions[0].timestamp = resp.transactions[0].timestamp;
                state.originalShipmentStringified = JSON.stringify(state.shipment);
                success = true;
            }

            state.saving = false;
        }

        return success;
    }

    const isDirty = computed((): boolean => JSON.stringify(state.shipment) !== state.originalShipmentStringified);

    const isReceivedTransactionLineFieldsDisabled = computed(
        (): boolean => !(state.disputing || (Permissions.ADMINISTRATION.canCloseoutDisputedTransactions() && state.transaction.status === TransactionStatus.DISPUTED)),
    );

    const transactionLineErrors = computed(() => validationTransactionResult?.model.transactionLines || validationCloseoutDisputedTransactionResult.model.transactionLines);

    const transactionLineFields = computed(
        (): Array<string> => (state.disputing || state.transaction.status === TransactionStatus.DISPUTED ? ['quantity', 'name', 'shortName', 'receivedQuantity'] : ['quantity', 'name', 'shortName']),
    );

    function goToTransactionList() {
        router.push({ name: transactionListRouteName });
    }

    async function confirmation(isShipNow: boolean): Promise<boolean> {
        return confirm({
            title: isShipNow ? getTitleCaseTranslation('core.button.shipNow') : getTitleCaseTranslation('core.button.moveToYard'),
            message: isShipNow ? getTranslation('core.validation.confirmShipNow') : getTranslation('core.validation.confirmMoveToYard'),
        });
    }

    async function closeoutDisputed() {
        validateCloseoutDisputedTransactionForm(state.transaction as Transaction);

        if (validationCloseoutDisputedTransactionResult.isValid) {
            const success = await transactionService.closeoutDisputedTransaction(state.transaction as Transaction);

            if (success) {
                goToTransactionList();
            }
        }
    }

    async function moveTransactionAndPrintReceipt(shipmentId: number, moveTransactionAction: (shipmentId: number) => Promise<any>) {
        const result = await moveTransactionAction(shipmentId);

        if (result) {
            state.originalShipmentStringified = JSON.stringify(state.shipment);
            await shipmentService.getShipmentReceipt(shipmentId);
            goToTransactionList();
        }
    }

    async function moveTransactionOutOfFloor(moveTransactionAction: (shipmentId: number) => Promise<any>, isShipNow: boolean = false) {
        if (state.transaction.isExisting && isDirty.value) {
            const updateSuccess = await updateExistingShipment();
            if (!updateSuccess) {
                return;
            }
        }

        state.shipping = true;

        if (state.shipment.id) {
            await moveTransactionAndPrintReceipt(state.shipment.id, moveTransactionAction);
        } else {
            state.shipment.clearTransactions();
            state.shipment.addTransaction(state.transaction as Transaction);
            const newShipmentId = await shipmentService.saveNewPickedShipment(state.shipment, true);

            if (newShipmentId && await confirmation(isShipNow)) {
                await moveTransactionAndPrintReceipt(newShipmentId, moveTransactionAction);
            }
        }

        state.shipping = false;
    }

    async function shipNow() {
        await moveTransactionOutOfFloor((shipmentId: number) => shipmentService.shipNow(shipmentId), true);
    }

    return {
        closeoutDisputed,
        goToTransactionList,
        isDirty,
        isReceivedTransactionLineFieldsDisabled,
        moveTransactionOutOfFloor,
        shipNow,
        transactionLineErrors,
        transactionLineFields,
        updateExistingShipment,
        validateShipmentForm,
        validationShipmentResult,
        validateTransactionForm,
        validationTransactionResult,
    };
}
