import ItemApiService from '@/services/api/ItemApiService';
import Item from '@/domain/Item';
import ItemSearchStringFilter from '@/domain/filters/ItemSearchStringFilter';
import { useNotification } from '@/composable/useNotifications';
import { getTitleCaseTranslation } from './TranslationService';

export default class ItemService {
    private itemApiService: ItemApiService;

    private notification = useNotification();

    constructor() {
        this.itemApiService = new ItemApiService();
    }

    public async getAllItems(): Promise<{ items: Array<Item>; success: boolean }> {
        const { data, success } = await this.itemApiService.getAllItems();
        return { items: data, success };
    }

    public async getItemById(id: number): Promise<{ item: Item; success: boolean; message: string }> {
        const { data, success, message } = await this.itemApiService.getItemById(id);
        return { item: data, success, message };
    }

    public async getItemsById(ids: Array<number>): Promise<Array<Item>> {
        const response = await this.itemApiService.getItemsById(ids);
        return response.data;
    }

    public async deleteItem(id: number): Promise<boolean> {
        const response = await this.itemApiService.deleteItem(id);
        return response.success;
    }

    public async updateItem(item: Item): Promise<boolean> {
        const response = await this.itemApiService.updateItem(item);

        if (response.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.updatedItem'));
        }

        return response.success;
    }

    public async addNewItem(item: Item): Promise<number | null> {
        const response = await this.itemApiService.addNewItem(item);
        if (!response.success) {
            return null;
        }
        this.notification.showSuccess(`${getTitleCaseTranslation('core.common.added')} ${item.name}`);
        return response.data;
    }

    public async findItemsByName(filter: ItemSearchStringFilter): Promise<Array<Item>> {
        const response = await this.itemApiService.findItemsByName(filter.toDTO);
        return response.success ? response.data : [];
    }

    public async findItemByConfig(filter: ItemSearchStringFilter, fromLocationId: number, toLocationId: number): Promise<Array<Item>> {
        const response = await this.itemApiService.findItemByNameWithRouteLocations(filter.toDTO, fromLocationId, toLocationId);
        return response.data;
    }
}
