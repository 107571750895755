import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-588485b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row row-cols-3 gy-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_b_col, {
      cols: _ctx.cols,
      lg: _ctx.lg,
      md: _ctx.md,
      sm: _ctx.sm,
      xs: _ctx.xs
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_form_input, {
          disabled: _ctx.disabled,
          error: _ctx.error,
          label: _ctx.label,
          "model-value": _ctx.formattedDateString,
          placeholder: _ctx.placeholder,
          "prevent-typing": "",
          onClick: _ctx.showTimeScheduler
        }, null, 8, ["disabled", "error", "label", "model-value", "placeholder", "onClick"])
      ]),
      _: 1
    }, 8, ["cols", "lg", "md", "sm", "xs"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showTimeScheduler,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showTimeScheduler) = $event)),
      "hide-header-close": "",
      "static-backdrop": "",
      title: _ctx.modalTitle,
      onOk: _ctx.updateTime,
      onCancel: _ctx.resetSelectedTime
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTimesInMinutes, (timeslot) => {
            return (_openBlock(), _createElementBlock("div", {
              key: timeslot,
              class: "col center"
            }, [
              _createVNode(_component_b_button, {
                variant: _ctx.state.selectedTime === timeslot ? 'primary' : 'secondary',
                onClick: () => _ctx.setTimeslot(timeslot)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.timeFormat.format(new Date(1970, 0, 1, 0, timeslot))), 1)
                ]),
                _: 2
              }, 1032, ["variant", "onClick"])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onOk", "onCancel"])
  ], 64))
}