import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b0dd129"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "progress" }
const _hoisted_5 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.messageString && _ctx.percentVal <= 100)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.messageString), 1))
      : (_ctx.overflowMessageString && _ctx.percentVal > 100)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.overflowMessageString), 1))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(['progress-bar', {'overflow': _ctx.percentVal > 100 }]),
        role: "progressbar",
        style: _normalizeStyle({width: _ctx.percentString}),
        "aria-valuenow": _ctx.percentString,
        "aria-valuemin": "0",
        "aria-valuemax": "100"
      }, null, 14, _hoisted_5)
    ])
  ]))
}