import DockApiService from '@/services/api/DockApiService';
import Dock from '@/domain/Dock';
import DockType from '@/domain/DockType';
import { useNotification } from '@/composable/useNotifications';
import { getTranslation } from '@/services/TranslationService';

export default class DockService {
    private dockApiService: DockApiService;

    private notification = useNotification();

    constructor() {
        this.dockApiService = new DockApiService();
    }

    public async addDock(dock: Dock): Promise<{ success: boolean; dock: Dock }> {
        const response = await this.dockApiService.addDock(dock);
        if (response.success) {
            this.notification.showSuccess();
        }
        return { success: response.success, dock: response.data };
    }

    public async updateDock(dock: Dock): Promise<Dock | null> {
        const response = await this.dockApiService.updateDock(dock);
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.dockUpdated'));
            return response.data;
        }
        return null;
    }

    public async deleteDock(id: number): Promise<boolean> {
        const response = await this.dockApiService.deleteDock(id);
        if (response.success) {
            this.notification.showSuccess();
        }
        return response.success;
    }

    public async getAllDocksByLocationId(locationId: number): Promise<Array<Dock>> {
        const response = await this.dockApiService.getDocksByLocationId(locationId);
        if (!response.success) {
            return [];
        }
        return response.data;
    }

    public async getInServiceShippingDocksByLocationId(locationId: number): Promise<Array<Dock>> {
        const response = await this.getAllDocksByLocationId(locationId);
        return response.filter((d) => d.inService && d.isShipping);
    }

    public async getInServiceReceivingDocksByLocationId(locationId: number): Promise<Array<Dock>> {
        const response = await this.getAllDocksByLocationId(locationId);
        return response.filter((d) => d.inService && d.isReceiving);
    }

    public async getDockTypes(): Promise<Array<DockType>> {
        const response = await this.dockApiService.getDockTypes();
        if (!response.success) {
            return [];
        }

        return response.data;
    }
}
