import ItemSearchStringFilterDTO from '@/dtos/filters/ItemSearchStringFilterDTO';

export default class ItemSearchStringFilter {
    public excludeItemIds?: number[];
    public ignoreCombinedContainers?: boolean;
    public includeOnlyCombinedContainers?: boolean;
    public includeOnlyRepairableItems?: boolean;
    public searchString: string = '';

    constructor(init?: Partial<ItemSearchStringFilter>) {
        this.excludeItemIds = init?.excludeItemIds;
        this.ignoreCombinedContainers = init?.ignoreCombinedContainers;
        this.includeOnlyCombinedContainers = init?.includeOnlyCombinedContainers;
        this.includeOnlyRepairableItems = init?.includeOnlyRepairableItems;
        this.searchString = init?.searchString || '';
    }

    get toDTO() : ItemSearchStringFilterDTO {
        return new ItemSearchStringFilterDTO(this);
    }
}
