export default class DockType {
    constructor(init?: Partial<DockType>) {
        Object.assign(this, init);
    }

    public id!: number;

    public description: string = '';

    public toString() {
        return this.description;
    }
}
