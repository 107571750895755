
import {
    computed, defineComponent, PropType, reactive,
} from 'vue';
import BButton from './bootstrap-library/BButton.vue';
import BCol from './bootstrap-library/BCol.vue';
import BFormInput from './bootstrap-library/BFormInput.vue';
import BModal from './bootstrap-library/modal/BModal.vue';
import Location from '@/domain/Location';
import { formatTimeFromMinutes } from '@/functions/date';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    selectedTime?: number;
    showTimeScheduler: boolean;
}

export default defineComponent({
    name: 'timepicker',
    components: {
        BButton,
        BCol,
        BFormInput,
        BModal,
    },
    props: {
        cols: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        error: { type: String, default: undefined },
        label: { type: String, default: undefined },
        lg: { type: String, default: '' },
        location: { type: Object as PropType<Location>, required: true },
        md: { type: String, default: '' },
        modalTitle: { type: String, default: () => getTitleCaseTranslation('core.common.selectTime') },
        modelValue: { type: Date, default: undefined },
        placeholder: { type: String, default: undefined },
        sm: { type: String, default: '' },
        xs: { type: String, default: '' },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const timeFormat = new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            hourCycle: 'h23',
            minute: 'numeric',
        });

        function getDateToMinutes(date: Date) : number {
            return date.getHours() * 60 + date.getMinutes();
        }

        const state = reactive<State>({
            selectedTime: props.modelValue ? getDateToMinutes(props.modelValue) : undefined,
            showTimeScheduler: false,
        });

        const availableTimesInMinutes = computed((): number[] => {
            const availableTimes: number[] = [];
            const minutesInADay = 1440;
            const timeslotDuration = props.location.timeslotDuration ?? 60;

            const startTimeInMinutes = props.location.isOpen24Hours ? 0 : getDateToMinutes(props.location.openTime);
            const endTimeInMinutes = props.location.isOpen24Hours ? minutesInADay : getDateToMinutes(props.location.closeTime);

            for (let minutes = startTimeInMinutes; minutes < endTimeInMinutes; minutes += timeslotDuration) {
                availableTimes.push(minutes);
            }

            return availableTimes;
        });

        const formattedDateString = computed((): string => {
            if (props.modelValue) {
                return `${props.modelValue.getHours()}:${props.modelValue.getMinutes().toString().padStart(2, '0')}`;
            }
            return '';
        });

        function resetSelectedTime() {
            state.selectedTime = props.modelValue ? getDateToMinutes(props.modelValue) : undefined;
        }

        function setTimeslot(minutes: number) {
            state.selectedTime = minutes;
        }

        function showTimeScheduler() {
            state.showTimeScheduler = true;
        }

        function updateTime() {
            if (state.selectedTime) {
                const updatedTime = new Date();

                updatedTime.setHours(0, state.selectedTime, 0, 0);

                emit('update:modelValue', updatedTime);
            }
        }

        return {
            availableTimesInMinutes,
            formattedDateString,
            formatTimeFromMinutes,
            resetSelectedTime,
            setTimeslot,
            showTimeScheduler,
            state,
            timeFormat,
            updateTime,
        };
    },
});
