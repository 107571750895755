import Location from '@/domain/Location';
import Carrier from '@/domain/Carrier';
import PlannedReservationDTO from '@/dtos/PlannedReservationDTO';
import { normalizeDate } from '@/functions/date';

export default class PlannedReservation {
    public id?: number = 0;
    public fromLocation: Location = new Location();
    public toLocation: Location = new Location();
    public carrier: Carrier = new Carrier();
    public reservationDateTime: Date = new Date();
    public carrierReferenceNumber: string = '';
    public partnerReferenceNumber: string = '';
    public trailerNumber: string = '';

    constructor(init?: Partial<PlannedReservation>) {
        Object.assign(this, init);

        if (init?.reservationDateTime) {
            this.reservationDateTime = normalizeDate(init.reservationDateTime);
        }
    }

    get fromLocationName(): string {
        return this.fromLocation.name;
    }

    get toLocationName(): string {
        return this.toLocation.name;
    }

    get carrierName(): string {
        return this.carrier.name;
    }

    public toDTO(): PlannedReservationDTO {
        return new PlannedReservationDTO(this);
    }
}
