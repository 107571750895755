
import { defineComponent, PropType, reactive } from 'vue';
import { ItemType } from '@/domain/enums/ItemType';
import ItemPicker from '@/components/ItemPicker.vue';
import Item from '@/domain/Item';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

type State = {
    bindRouteItems: boolean;
};
export default defineComponent({
    name: 'all-or-on-route-item-selector',
    components: {
        ItemPicker,
    },
    props: {
        allowQuantityChange: {
            type: Boolean,
            default: () => false,
        },
        ignoreCombinedContainers: {
            type: Boolean,
            default: () => false,
        },
        shipping: {
            type: Object as PropType<{ toLocationId: number; fromLocationId: number }>,
            default: () => ({
                toLocationId: 0,
                fromLocationId: 0,
            }),
        },
    },
    emits: ['onSelect'],
    setup(_, context) {
        const state = reactive<State>({
            bindRouteItems: true,
        });

        function addItems(data:{item: Item, quantity: number}) {
            context.emit('onSelect', data);
        }

        function getOnRouteItems() {
            state.bindRouteItems = true;
        }

        function getAllItems() {
            state.bindRouteItems = false;
        }

        return {
            state,
            ItemType,
            getAllItems,
            getOnRouteItems,
            addItems,
            getTitleCaseTranslation,
            getTranslation,
        };
    },
});

