import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_item_picker = _resolveComponent("item-picker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_b_button, {
      class: _normalizeClass(_ctx.state.bindRouteItems ? 'item-btn' : 'item-btn btn-tertiary'),
      variant: "primary",
      onClick: _ctx.getOnRouteItems
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.onRoute')), 1)
      ]),
      _: 1
    }, 8, ["class", "onClick"]),
    _createVNode(_component_b_button, {
      class: _normalizeClass(_ctx.state.bindRouteItems ? 'item-btn btn-tertiary' : 'item-btn' ),
      variant: "primary",
      onClick: _ctx.getAllItems
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.allItems')), 1)
      ]),
      _: 1
    }, 8, ["class", "onClick"]),
    _createVNode(_component_item_picker, {
      key: `${_ctx.state.bindRouteItems}`,
      placeholder: _ctx.getTranslation('core.common.typeToSearchForItem'),
      "allow-quantity-change": _ctx.allowQuantityChange,
      "item-type": _ctx.state.bindRouteItems ? _ctx.ItemType.SHIPPING : _ctx.ItemType.ALL,
      shipping: _ctx.shipping,
      "ignore-combined-containers": _ctx.ignoreCombinedContainers,
      onOnSelect: _ctx.addItems
    }, null, 8, ["placeholder", "allow-quantity-change", "item-type", "shipping", "ignore-combined-containers", "onOnSelect"])
  ], 64))
}