
import { defineComponent, ref } from 'vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';

interface NumberInput extends HTMLElement {
    numberChange: (val: number) => void;
    stop: () => void;
}

export default defineComponent({
    name: 'quantity-picker',
    components: { BFormInput },
    props: {
        modelValue: {
            type: Number,
            default: 0,
        },
        min: {
            type: Number,
            default: () => 0,
        },
        maxQuantityWarn: {
            type: Number,
            default: () => 200,
        },
        focusOnCreate: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '0',
        },
        label: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, context) {
        const numberInput = ref<NumberInput | null>(null);

        function onInputChange(num: number) {
            const value = num >= props.min ? Number(num) : props.min;
            context.emit('update:modelValue', value);
            context.emit('change', value);
        }

        return {
            numberInput,
            onInputChange,
        };
    },
});
