
import {
    computed, defineComponent, PropType, unref,
} from 'vue';

export default defineComponent({
    name: 'b-progress-bar',
    props: {
        percent: {
            type: Object as PropType<number>,
            required: true,
        },
        overflowMessage: {
            type: Object as PropType<string>,
        },
        message: {
            type: Object as PropType<string>,
        },
    },
    setup(props) {
        const percentString = computed((): string => {
            const val = unref(props.percent);
            return `${val.toFixed(0)}%`;
        });

        const percentVal = computed((): number => unref(props.percent));

        const messageString = computed((): string => {
            const val = unref(props.message);
            return `${val}`;
        });

        const overflowMessageString = computed((): string => {
            const val = unref(props.overflowMessage);
            return `${val}`;
        });

        return {
            percentString,
            messageString,
            overflowMessageString,
            percentVal,
        };
    },
});

