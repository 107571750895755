import NonWorkingTimeSet from '@/domain/Dates/NonWorkingTimeSet';
import { isSameDay } from '@/functions/date';
import NonWorkDays from '@/domain/Dates/NonWorkDays';
import NonWorkDates from '@/domain/Dates/NonWorkDates';
import coreStore from '@/store/CoreStore';

export type ShipDateCalendarData = {
    newShipDateMin: Date,
    newShipDateMax: Date,
    newShipDateDisabled: Array<Date>,
    adjustConfirmedShipDateMin: Date,
    adjustConfirmedShipDateDisabled: Array<Date>,
};

export type DeliveryDateCalendarData = {
    deliveryDateMax: Date,
    deliveryDateDisabled: Array<Date>
};

export type NeedByDateCalendarData = {
    needByDateMin: Date,
    needByDateMax: Date,
    needByDateDisabled: Array<Date>
};

export default class NonWorkDayService {
    private readonly minDaysOutNeedBy: number;

    private readonly maxDaysOutNeedBy: number;

    private timeSetData: Array<NonWorkingTimeSet>;

    constructor(timeSets: Array<NonWorkingTimeSet>) {
        const { orderDateRangeStart, orderDateRangeEnd } = coreStore.getInstance().pemSettingsStore;
        this.timeSetData = timeSets;
        this.minDaysOutNeedBy = orderDateRangeStart;
        this.maxDaysOutNeedBy = orderDateRangeEnd;
    }

    getNextWorkingDay(date: Date, timeSetId?: number): Date {
        if (this.isNonWorkDay(date, timeSetId)) return this.getDateWorkingDaysAhead(1, date, timeSetId);
        return date;
    }

    getPreviousWorkingDay(date: Date, timeSetId?: number): Date {
        if (this.isNonWorkDay(date, timeSetId)) return this.getDateWorkingDaysBehind(1, date, timeSetId);
        return date;
    }

    private getDateWorkingDaysAhead(numberOfDays: number, originalDate: Date, timeSetId?: number): Date {
        let dateResult = new Date(originalDate);

        while (numberOfDays > 0) {
            dateResult = new Date(dateResult.setDate(dateResult.getDate() + 1));
            if (!this.isNonWorkDay(dateResult, timeSetId)) {
                // eslint-disable-next-line no-param-reassign
                numberOfDays--;
            }
        }
        return dateResult;
    }

    private getDateWorkingDaysBehind(numberOfDays: number, originalDate: Date, timeSetId?: number): Date {
        let dateResult = new Date(originalDate);

        while (numberOfDays > 0) {
            dateResult = new Date(dateResult.setDate(dateResult.getDate() - 1));
            if (!this.isNonWorkDay(dateResult, timeSetId)) {
                // eslint-disable-next-line no-param-reassign
                numberOfDays--;
            }
        }
        return dateResult;
    }

    private isNonWorkDay(date: Date | string, timeSetId?: number): boolean {
        const result = this.isNonWorkDate(date, timeSetId) || this.isNonWorkDayOfWeek(date, timeSetId);

        return result;
    }

    private getNonWorkDays(timeSetId?: number): Array<NonWorkDays> {
        let result;

        if (timeSetId) {
            result = this.timeSetData.find((x) => x.id === timeSetId)?.nonWorkDays || [];
        } else {
            result = this.timeSetData.find((x) => x.isDefaultTimeSet)?.nonWorkDays || [];
        }

        return result;
    }

    private getNonWorkDates(timeSetId?: number): Array<NonWorkDates> {
        let result;

        if (timeSetId) {
            result = this.timeSetData.find((x) => x.id === timeSetId)?.nonWorkDates || [];
        } else {
            result = this.timeSetData.find((x) => x.isDefaultTimeSet)?.nonWorkDates || [];
        }

        return result;
    }

    private isNonWorkDate(date: Date | string, timeSetId?: number): boolean {
        const nonWorkDates = this.getNonWorkDates(timeSetId);
        const dateObj = new Date(date);

        const nonWorkDate = nonWorkDates.find((x) => isSameDay(dateObj, x.nonWorkDate));

        return !!nonWorkDate;
    }

    private isNonWorkDayOfWeek(date: Date | string, timeSetId?: number): boolean {
        const nonWorkDays = this.getNonWorkDays(timeSetId);
        const dateObj = new Date(date);
        const dayOfWeek = dateObj.getDay();
        const nonWorkDay = nonWorkDays.find((x) => x.nonWorkDay === dayOfWeek);

        const result = !!nonWorkDay;

        return result;
    }

    getShipDateCalendarData(timeSetId?: number, workingDate: Date = new Date()): ShipDateCalendarData {
        return this.getShipDateCalendarDataBetweenDates(workingDate, 1, workingDate, 5, timeSetId);
    }

    getShipDateCalendarDataBetweenDates(startDate: Date, daysBehind: number, endDate: Date, daysAhead: number, timeSetId?: number) : ShipDateCalendarData {
        const startDateCopy = new Date(startDate);
        const endDateCopy = new Date(endDate);

        startDateCopy.setHours(0, 0, 0, 0);
        const newShipDateMin = this.getDateWorkingDaysBehind(daysBehind, startDateCopy, timeSetId);
        endDateCopy.setHours(23, 59, 59);
        const newShipDateMax = this.getDateWorkingDaysAhead(daysAhead, endDateCopy, timeSetId);
        const newShipDateDisabled = this.nonWorkingDatesWithinDateRange(newShipDateMin, newShipDateMax, timeSetId);
        const adjustConfirmedShipDateMin = this.getDateWorkingDaysBehind(90, startDateCopy, timeSetId);
        const adjustConfirmedShipDateDisabled = this.nonWorkingDatesWithinDateRange(adjustConfirmedShipDateMin, startDateCopy, timeSetId);

        return {
            newShipDateMax,
            newShipDateMin,
            newShipDateDisabled,
            adjustConfirmedShipDateMin,
            adjustConfirmedShipDateDisabled,
        };
    }

    getDeliveryDateCalendarDataBetweenDates(daysAhead: number, startDate?: Date | null, timeSetId?: number) : DeliveryDateCalendarData {
        const deliveryDateMax = this.getDateWorkingDaysAhead(daysAhead, new Date(), timeSetId);
        const deliveryDateDisabled = this.nonWorkingDatesWithinDateRange(startDate ?? new Date(), deliveryDateMax, timeSetId);
        return {
            deliveryDateMax,
            deliveryDateDisabled,
        };
    }

    getNeedByDateCalendarData(timeSetId?: number): NeedByDateCalendarData {
        const needByDateMin = new Date();
        needByDateMin.setDate(needByDateMin.getDate() + this.minDaysOutNeedBy);
        needByDateMin.setHours(0, 0, 0, 0);
        const needByDateMax = new Date();
        needByDateMax.setDate(needByDateMax.getDate() + this.maxDaysOutNeedBy);
        needByDateMax.setHours(23, 59, 59);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const needByDateMinCalendar = needByDateMin;
        const needByDateMaxCalendar = needByDateMax;
        const datesAheadDisabled = this.disableAllDatesAhead(this.minDaysOutNeedBy, currentDate);
        const nonWorkingDatesInRange = this.nonWorkingDatesWithinDateRange(needByDateMin, needByDateMax, timeSetId);
        const needByDateDisabled = [...datesAheadDisabled, ...nonWorkingDatesInRange];

        return {
            needByDateMin: needByDateMinCalendar,
            needByDateMax: needByDateMaxCalendar,
            needByDateDisabled,
        };
    }

    private nonWorkingDatesWithinDateRange(dateFrom: Date, dateTo: Date, timeSetId?: number): Array<Date> {
        const nonWorkingDates: Array<Date> = [];

        let loop = new Date(dateFrom);
        while (loop <= dateTo) {
            if (this.isNonWorkDay(loop, timeSetId)) {
                nonWorkingDates.push(new Date(loop));
            }
            loop = new Date(loop.setDate(loop.getDate() + 1));
        }

        return nonWorkingDates;
    }

    private disableAllDatesAhead(numberOfDays: number, startDate: Date): Array<Date> {
        const disabledDates: Array<Date> = [];

        let loop = new Date(startDate);
        for (let i = 0; i < numberOfDays; i++) {
            disabledDates.push(new Date(loop));
            loop = new Date(loop.setDate(loop.getDate() + 1));
        }

        return disabledDates;
    }
}
