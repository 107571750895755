import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import LocationRouteConfig from '@/domain/LocationRouteConfig';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import RouteConfigAddRouteDTO from '@/dtos/RouteConfigAddRouteDTO';
import RouteConfigDetailsDTO from '@/dtos/RouteConfigDetailsDTO';
import RouteAddItemDTO from '@/dtos/RouteAddItemDTO';
import Item from '@/domain/Item';
import Location from '@/domain/Location';
import InventoryCategory from '@/domain/InventoryCategory';

export default class RouteConfigApiService {
    public async getRouteConfigsFromLocation(locationId: number): Promise<DataAccessResponse<Array<LocationRouteConfig>>> {
        const response: AxiosResponse<DataAccessResponse<Array<LocationRouteConfig>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/${locationId}`,
        });

        return new DataListAccessResponseClass<LocationRouteConfig>(response, LocationRouteConfig).response;
    }

    public async getAllRouteConfigsByLocation(locationId: number): Promise<DataAccessResponse<Array<LocationRouteConfig>>> {
        const response: AxiosResponse<DataAccessResponse<Array<LocationRouteConfig>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/all-routes-by-location/${locationId}`,
        });

        return new DataListAccessResponseClass<LocationRouteConfig>(response, LocationRouteConfig).response;
    }

    public async addRoute(routeData: RouteConfigAddRouteDTO): Promise<DataAccessResponse<LocationRouteConfig>> {
        const response: AxiosResponse<DataAccessResponse<LocationRouteConfig>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration`,
            data: routeData,
        });
        return new DataAccessResponseClass<LocationRouteConfig>(response, LocationRouteConfig).response;
    }

    public async updateItemsInRoute(dto: RouteAddItemDTO): Promise<DataAccessResponse<{ routeId: number; itemCount: number }>> {
        const response: AxiosResponse<DataAccessResponse<{ routeId: number; itemCount: number }>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/items/update`,
            data: dto,
        });

        return new DataAccessResponseClass<{ routeId: number; itemCount: number }>(response).response;
    }

    async getAllShipFromInventoryCategoriesInRouteConfig(fromLocationId: number, toLocationId: number): Promise<DataAccessResponse<Array<InventoryCategory>>> {
        const response: AxiosResponse<DataAccessResponse<Array<InventoryCategory>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/inventory-categories/from/${fromLocationId}/to/${toLocationId}`,
        });

        return new DataListAccessResponseClass<InventoryCategory>(response, InventoryCategory).response;
    }

    async getAllItemsInRouteConfig(fromLocationId: number, toLocationId: number): Promise<DataAccessResponse<Array<Item>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Item>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/items/from/${fromLocationId}/to/${toLocationId}`,
        });

        return new DataListAccessResponseClass<Item>(response, Item).response;
    }

    async getAllItemsInToLocation(toLocationId: number): Promise<DataAccessResponse<Array<Item>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Item>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/items/to/${toLocationId}`,
        });

        return new DataListAccessResponseClass<Item>(response).response;
    }

    async deleteShippingRoute(routeId: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/${routeId}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async updateRouteDetails(dto: RouteConfigDetailsDTO): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/details/update`,
            data: dto,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async getAllFromLocations(): Promise<DataAccessResponse<Array<Location>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Location>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/allFromLocations`,
        });

        return new DataListAccessResponseClass<Location>(response, Location).response;
    }

    public async getAllToLocations(): Promise<DataAccessResponse<Array<Location>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Location>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/allToLocations`,
        });

        return new DataListAccessResponseClass<Location>(response, Location).response;
    }

    public async getAllItemsWithRoute(): Promise<DataAccessResponse<Array<Item>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Item>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/allItemsWithRoute`,
        });

        return new DataListAccessResponseClass<Item>(response, Item).response;
    }

    public async getRouteConfigByFromAndToLocation(fromLocationId: number, toLocationId: number): Promise<DataAccessResponse<LocationRouteConfig>> {
        const response: AxiosResponse<DataAccessResponse<LocationRouteConfig>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shippingrouteconfiguration/from/${fromLocationId}/to/${toLocationId}`,
        });

        return new DataAccessResponseClass<LocationRouteConfig>(response, LocationRouteConfig).response;
    }
}
