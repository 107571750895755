import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-207fb02b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "label-error-container"
}
const _hoisted_2 = {
  key: 0,
  class: "error"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "qty-picker-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label || _ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.error), 1))
            : (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_b_form_input, {
        ref: "numberInput",
        disabled: _ctx.disabled,
        "model-value": _ctx.modelValue,
        autofocus: _ctx.focusOnCreate,
        min: _ctx.min,
        type: "number",
        placeholder: _ctx.placeholder,
        class: _normalizeClass({ 'input-quantity': true, 'input-danger': _ctx.modelValue >= _ctx.maxQuantityWarn }),
        "hide-stepper": "",
        "number-options": { allowsNegative: _ctx.min < 0 },
        required: _ctx.required,
        onChange: _ctx.onInputChange
      }, null, 8, ["disabled", "model-value", "autofocus", "min", "placeholder", "class", "number-options", "required", "onChange"]),
      _createVNode(_component_b_button, {
        id: "quantity-picker-button-increment",
        disabled: _ctx.disabled,
        class: "btn-counter",
        onMousedown: _cache[0] || (_cache[0] = () => _ctx.numberInput?.numberChange(1)),
        onMouseup: _cache[1] || (_cache[1] = () => _ctx.numberInput?.stop())
      }, {
        default: _withCtx(() => [
          _createVNode(_component_faicon, { icon: "plus" })
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_b_button, {
        id: "quantity-picker-button-decrement",
        disabled: _ctx.disabled,
        class: "btn-counter decrement",
        onMousedown: _cache[2] || (_cache[2] = () => _ctx.numberInput?.numberChange(-1)),
        onMouseup: _cache[3] || (_cache[3] = () => _ctx.numberInput?.stop())
      }, {
        default: _withCtx(() => [
          _createVNode(_component_faicon, { icon: "minus" })
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ], 64))
}