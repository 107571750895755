import TransactionDTO from '@/modules/floortrak/domain/jigsaw/dto/TransactionDTO';
import Shipment from '@/domain/Shipment';
import { DTOBuilder } from '@/modules/floortrak/domain/jigsaw/dto/DTOBuilder';

export default class ShipmentDTO extends DTOBuilder {
    public name: string = '';

    public carrierId!: number;

    public holdLocationId!: number;

    public driver!: string;

    public trailerTypeId: number | null = null;

    public trailer!: string;

    public seal!: string;

    public freightOrder!: string;

    public carrierRoute!: string;

    public deliveryNotes!: string;

    public dockDoor!: string;

    public transactions: Array<TransactionDTO> = [];

    public workStartTime!: string;

    public workEndTime!: string; // datetime

    public dockDepartTime!: string; // datetime

    public dockArrivalTime!: string; // datetime

    public timestamp!: string;

    public lot?: string;

    public scheduledDockTimeSlot?: string; // datetime

    public dockId!: number;

    constructor(shipment: Shipment) {
        super();
        this.map<ShipmentDTO, Shipment>(this, shipment);

        if (shipment.dock) this.dockId = shipment.dockId;
        if (shipment.holdLocation) this.holdLocationId = shipment.holdLocation.id;
        if (shipment.trailerType && shipment.trailerType.id > 0) this.trailerTypeId = shipment.trailerType.id;
        if (shipment.transactions) this.transactions = shipment.transactions.map((tx) => new TransactionDTO(tx));
    }

    public dateToTime(date: Date): number {
        let result = 0;
        result += date.getHours() * 60;
        result += date.getMinutes();
        return result;
    }
}
