
import { defineComponent, PropType } from 'vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import { Variant } from '@/types';
import { arrayBufferToBase64 } from '@/functions/image';
import useStringFormatter from '@/composable/useStringFormatter';

export default defineComponent({
    name: 'button-upload-image',
    components: { BButton },
    props: {
        buttonVariant: { type: String as PropType<Variant>, default: 'primary' },
        buttonText: { type: String, default: 'Upload Image' },
        disabled: { type: Boolean, default: false },
    },
    emits: ['onUpload'],
    setup(props, context) {
        const { titleCase } = useStringFormatter();

        async function changeImage(evt: Event) {
            // we can't pass a byte[] in json to the server
            // so we have to get down into the weeds to grab the file content and convert to a base64 string
            const target = evt.target as HTMLInputElement;
            if (target?.files) {
                const base64String = arrayBufferToBase64(await target?.files[0].arrayBuffer());
                context.emit('onUpload', {
                    base64String,
                    type: target?.files[0].type,
                });
                target.value = '';
            }
        }

        return {
            changeImage,
            titleCase,
        };
    },
});
