import axios, { AxiosResponse } from 'axios';
import Shipment from '@/domain/Shipment';
import Transaction from '@/domain/Transaction';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import ShipmentDTO from '@/modules/floortrak/domain/jigsaw/dto/ShipmentDTO';
import { API_SERVICE_URL } from '@/config/env';
import { downloadFile } from '@/functions/DownloadDocuments';

export default class PlannedShipmentAPIService {
    public async searchAndActivateTransaction(transactionId: number, locationId: number): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping/transaction-activation`,
            data: {
                locationId,
                transactionId,
            },
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async saveNewPickedShipment(shipment: ShipmentDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping/picked-shipment`,
            data: shipment,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async saveNewScheduledShipment(shipment: ShipmentDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping/scheduled-shipment`,
            data: shipment,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async createAllocatedShipmentFromPlannedTransaction(transactionId: number): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping/allocate-planned-transaction/${transactionId}`,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async createAllocatedShipmentFromPlannedTransactionForLocation(transactionId: number, fromLocationId: number): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping/allocate-planned-transaction/${transactionId}/from/${fromLocationId}`,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async createAllocatedShipmentsFromPlannedTransactions(transactionIds: number[]): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping/allocate-planned-transactions/`,
            data: transactionIds,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async createAllocatedShipmentsFromPlannedTransactionsForLocation(transactionIds: number[], fromLocationId: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping/allocate-planned-transactions/from/${fromLocationId}`,
            data: transactionIds,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async getShipmentDetails(transactionId: number): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shipping/transaction-shipment-details/${transactionId}`,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async updateExistingShipment(shipment: ShipmentDTO): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping`,
            data: shipment,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async updateExistingScheduledShipment(shipment: ShipmentDTO): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping/scheduled-shipment`,
            data: shipment,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async shipNow(shipmentId: number): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping/ship/${shipmentId}`,
        });

        return new DataAccessResponseClass<Transaction>(response, Transaction).response;
    }

    public async getShipmentReceipt(id: number, shipDateLabel: string): Promise<DataAccessResponse<Blob>> {
        const response: AxiosResponse = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shipping/receipt/${id}`,
            params: { shipDateLabel },
            responseType: 'blob',
        });

        if (response.data && response.data.size > 0) {
            await downloadFile(`shipment_receipt_${id}.pdf`, response.data);
        }

        return new DataAccessResponseClass<Blob>(response).response;
    }

    public async moveTransactionsToHeld(shipmentId: number): Promise<{ success: boolean; data: number }> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping/move-to-held/${shipmentId}`,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateShipmentNotes(id: number, deliveryNotes: string): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'put',
            data: { id, deliveryNotes },
            url: `${API_SERVICE_URL}/shipping/notes`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async adjustConfirmedShipmentData(shipment: ShipmentDTO): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping/adjust-confirmed`,
            data: shipment,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }
}
